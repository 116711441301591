import { ReactElement } from 'react';

import fetchContentfulData from 'api/contentful-fetch';
// import { Banner, ComponentGenerator, HomeHero } from 'components/CMS';
import { ExtendedNextPageContext, ServerSideProps, UnknownObjectAny } from 'types/global.types';
import homepageQuery from '../graphql/queries/homepage';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';
import { CMSHomePageProps } from 'types/cms/homeFragments';
import { ComponentGenerator, HomeHeroSingleImage } from 'components/CMS';
import PageContainer from 'components/containers/PageContainer';

const Home = ({ content }: CMSHomePageProps): ReactElement => {
  // const formData = content?.formData;
  // const {formId, orgId, shouldDisplayForm} = formData??{};
  // const finalOrgId = orgId ?? process.env.CONTENTFUL_ORG_ID;
  return (
    <>
      <HomeHeroSingleImage {...content} />
      {/* {shouldDisplayForm && (
        <div
          data-form-id={formId}
          data-form-api-url={`https://public-can.mkt.dynamics.com/api/v1.0/orgs/${finalOrgId}/landingpageforms`}
          data-cached-form-url={`https://assets-can.mkt.dynamics.com/${finalOrgId}/digitalassets/forms/${formId}`}
        ></div>
      )} */}
      <PageContainer removeHero>
        {content?.bodyCollection?.items?.map((component: UnknownObjectAny, index: number) => (
          <ComponentGenerator
            key={`homepage-component-${index}`}
            __typename={component.__typename}
            {...component}
          />
        ))}
      </PageContainer>
    </>
  );
};

export async function getServerSideProps(context: ExtendedNextPageContext): ServerSideProps {
  const { preview = false } = context;
  const content = (await fetchContentfulData(
    { query: homepageQuery, variables: { preview } },
    preview
  )) as UnknownObjectAny;
  graphQLErrorHandler('homepageQuery', content?.errors, homepageQuery);
  // const form = (await fetchContentfulData(
  //   {
  //     query: pageQuery,
  //     variables: {
  //       slug: 'd365-form',
  //       preview:false
  //     }
  //   },
  //   false
  // )) as UnknownObjectAny;
  // const formData = form?.data?.pageCollection?.items?.[0]?.d365Form;
  // const orgId = formData?.orgId ?? process.env.CONTENTFUL_ORG_ID;
  // const formResponse = await fetch(`https://assets-can.mkt.dynamics.com/${orgId}/digitalassets/forms/${formData?.formId}`);
  // if (formData){
  //   formData.shouldDisplayForm = formResponse?.status === 200;
  // }
  // if (content?.data?.homePage){
  //   content.data.homePage.formData = formData;
  // }
  return { props: { content: content?.data?.homePage || null } };
}

export default Home;
